/*** Bootstrap ***/
@import "bootstrap_custom";
@import "~bootstrap/scss/bootstrap";


/*** Font Awesome Pro ***/
@import 'fontawesome/fontawesome';
@import 'fontawesome/light';
@import 'fontawesome/brands';
@import 'fontawesome/solid';

/*** lightGallery ***/
@import 'lightgallery/lightgallery';
 

/**************

 Multi Digital
 Wiedenstrasse 52c
 CH-9470 Buchs

 T +41 58 344 92 41
 hello@multidigital.ch
 www.multidigital.ch

***************/

/*** Fonts ***/ 
@import 'fonts';

/*** Datepicker ***/
@import 'daterangepicker';

@import 'bootstrap-datepicker3.min';

/*** Standard ***/
body, html { width: 100%; /* -webkit-font-smoothing: antialiased;  -moz-osx-font-smoothing: grayscale;*/ }
body { background-image: url('../img/footer_foto.jpg'); background-repeat: no-repeat; background-size: contain; background-position: center bottom; background-color: #e3e3e3; }
body, p { font-family: 'OpenSans'; font-size: 14px; margin: 0; padding: 0; font-weight: normal; color: #5a5a5a; }
a { color: #5a5a5a; text-decoration: none; /* border-bottom: 1px solid #df702b; padding-bottom:1px;*/ }
a:hover, a:focus { color: #e30613; text-decoration: none; }
strong { font-family: 'OpenSans-Semibold'; font-weight: normal; }
ul, menu, dir { display: block; list-style-type: disc; -webkit-margin-before: 0em; -webkit-margin-after: 0em; -webkit-margin-start: 0px; -webkit-margin-end: 0px; -webkit-padding-start: 0px; padding-left: 0; }
hr { margin-top: 20px; margin-bottom: 20px; border: 0; border-top: 1px solid #dadada; }
em { font-family: 'OpenSans-Italic'; font-weight: normal; font-style: normal; }
iframe { width: 100%; }
button:focus { outline: 0; }

select:not([multiple]) {-webkit-appearance: none;-moz-appearance: none;background-position: right 50%;background-repeat: no-repeat;background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);padding: .5em;padding-right: 1.5em}


/*** Basis ***/
.abstand_oben { margin-top: 30px; }
.abstand_oben_40 { margin-top: 40px; }
.abstand_unten { margin-bottom: 30px; }
.container { max-width: 1260px; width: 100%; padding: 0px 60px; }
.red_color { color: #e30613; }
.grau_hintergrund { background: #e3e3e3 }
.weisse_box { background: #fff; }
.padding_top_55 { padding-top: 53px; }
.btn.home { padding: 0; text-transform: uppercase; margin: 30px 0 50px 0; font-size: 14px; }
.btn.home i { margin-right: 5px; }
.form-control:focus { color: #333; background-color: transparent; border-color: transparent; box-shadow: none; }
.big-icon { font-size: 50px; }
.btn { border: 0 none; padding: 5px 10px; width: auto; border-radius: 0; margin-top: 10px; font-size: 16px; }
.btn-primary, .btn-primary:hover { background: #e30613; color: #fff; }
.btn-default, .btn-default:hover { background-color: #5a5a5a; border: 0 none; color: #fff; }
.modal li { margin-left: 20px; }
/* .banner-wrapper img { width: 100%; } */
.row.full { margin-left:-60px; margin-right:-60px; }

/*** Buttons ***/
.btn.grau {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    color: #999;
    margin-top: 5px;
    text-transform: uppercase;
    border: 0 none;
    padding: 0;
    font-size: 14px;
    line-height: 14px;
    border-radius: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-right: 10px;
}
.btn.grau i { margin-right: 2px; }
.btn.grau:hover { color: #e30613; }
.btn.rot { background: #e30613; font-family: 'Roboto-Medium'; text-transform: uppercase; color: #fff; font-weight: normal; border: 1px solid transparent; padding: 10px; font-size: 14px; line-height: 14px; border-radius: 0; transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
.btn.rot:hover { background: #5a5a5a; }
button.applyBtn { background: #e30613; color: #fff; }
button.applyBtn:hover { color: #fff; background-color: #5a5a5a; border-color: transparent; }

/*** Datepicker ***/
.daterangepicker .input-mini.active { border: 1px solid #5a5a5a; border-radius: 0px; }
.daterangepicker .input-mini { border: 1px solid #ccc; border-radius: 0px; color: #555; height: 30px; line-height: 30px; display: block; vertical-align: middle; margin: 0 0 5px 0; padding: 0 6px 0 28px; width: 100%; }
.daterangepicker button { display: block; margin-top: 0; margin-bottom: 10px; }
.daterangepicker .range_inputs { padding: 0 10px; }
.daterangepicker td.in-range { background-color: #e3e3e3; }
.daterangepicker td.in-range.available { background-color: #e30613; color: #fff; }
.daterangepicker td.active, .daterangepicker td.active:hover { background-color: #e30613; }

/*** Navigationspunkt ***/
.navigationspunkt { }
.navigationspunkt table { margin: 10px 0; }
.navigationspunkt table td { padding: 5px 0; border-bottom: 1px solid #dadada; vertical-align: top; }
.standard.navigationspunkt li { margin-left: 20px; }
.standard.navigationspunkt img { max-width: 100%; height: auto; }

/*** Platzhalter ***/
.platzhalter { background: #efefef; text-align: center; color: #999; padding: 10px; }
.platzhalter.full-banner { width: 468px; height: 60px; }
.platzhalter.halfpage { width: 300px; height: 600px; margin: 0 auto; vertical-align: middle; }
.platzhalter.rectangle { width: 300px; height: 250px; margin: 0 auto; }

/*** Header ***/
.top_navigation { list-style: none; text-transform: uppercase; color: #848386; padding: 20px 0 15px 0; line-height: 14px; font-family: 'Roboto-Regular'; }
.top_navigation li { float: left; }
.top_navigation li + li { border-left: 1px solid #dadada; margin-left: 10px; padding-left: 10px; }
.anmeldung { margin-top: 15px; font-family: 'Roboto-Medium'; text-align: right; }
.anmeldung a { color: #5a5a5a; text-transform: uppercase; }

/*** Hauptnavigation ***/

.navbar { padding: 0; }
.navbar .navbar-toggler { background-color: #e30613; }
.navbar-toggler-icon i { font-size: 28px; color: #fff; }
.navigation_box { border-top: 1px solid #848386; border-bottom: 1px solid #848386; padding: 15px 0; margin-top: 0px; position: relative; }
#navbarCollapse ul { list-style: none; font-family: 'Roboto-Medium'; font-size: 14px; }
#navbarCollapse li { float: left; margin-right: 20px; text-transform: uppercase; }
#navbarCollapse li a { display: block; }
#navbarCollapse li a.active { color: #e30613 }
#navbarCollapse .dropdown-menu { font-family: 'Roboto-Light'; font-weight: normal; left: 0; right: 0; margin: 15px 0 0 0; padding: 20px 0px 15px 0; font-size: 14px; color: #fff; background-color: #5a5a5a; border: 0 none; border-radius: 0; min-width: 13rem; }
#navbarCollapse .dropdown-menu a { color: #fff; display: block; }
#navbarCollapse .dropdown-menu li { margin-bottom: 5px; padding: 5px 25px; width: 100%; }
#navbarCollapse .dropdown-menu li:hover { background: #fff; }
#navbarCollapse .dropdown-menu li:hover a { color: #5a5a5a }
#navbarCollapse .dropdown-menu li.active { background: #fff; color: #e30613 }
.navigation_box .suche { font-size: 18px; line-height: 18px; position: absolute; right: 0; top: 16px; }
.navigation_box .suche a { color: #e30613; }
.navigation_box .nav-item.login { background-color: #e30613; margin-top: 10px; }
.navigation_box .nav-item.login a { color: #fff; padding: 5px; }
.navigation_box .nav-item.login i { color: #fff; }

/*** Topnews ***/
#topnews_carousel { margin-top: 40px; border-top: 2px solid #e30613 !important; min-height: 650px; }
#topnews_carousel .carousel-indicators { list-style: none; position: relative; right: auto; margin: 0; bottom: 0; }
#topnews_carousel .carousel-indicators li { float: left; background: #e3e3e3; text-align: center; padding: 10px 0; width: 20%; font-family: 'Roboto-Medium'; text-transform: uppercase; margin: 0; height: auto; flex: auto; text-indent: 0 }
#topnews_carousel .carousel-indicators li.active, #topnews_carousel .carousel-indicators li:hover { background: #fff; color: #e30613; cursor: pointer; }
#topnews_carousel h2 { margin-top: 15px; }
#topnews_carousel p { line-height: 21px; }
#topnews_carousel .carousel-control-next, #topnews_carousel .carousel-control-prev { position: absolute; top: 290px; margin-top: -56px; height: 50px; bottom: auto; width: 40px; color: #5a5a5a; text-align: center; opacity: 1; background: #fff; font-size: 40px; }
#topnews_carousel .carousel-control-next:hover { background: #e30613; color: #fff; }
#topnews_carousel .carousel-control-prev:hover { background: #e30613; color: #fff; }
#topnews_carousel .img { position: relative; }
#topnews_carousel .multimedia_icons { position: absolute; bottom: 0; width: 100%; }
#topnews_carousel .multimedia_icons .icon { background: #e30613; color: #fff; padding: 8px 10px; margin-left: 5px; }

/*** Artikel ***/
.artikel_liste { }
.artikel_liste article { border-bottom: 1px solid #dadada; padding-bottom: 30px; margin-bottom: 30px; margin-top: 10px; }
.artikel_liste article .thema { color: #999999; text-transform: uppercase; margin-top: -5px; margin-bottom: 5px; }
.artikel_liste article .multimedia_icons { position: absolute; bottom: 0; width: 100%; }
.artikel_liste article .icon { background: #e30613; color: #fff; padding: 8px 10px; margin-left: 5px; }
.artikel_liste article .foto, .artikel_liste article .text { position: relative; }
.artikel_liste article .gesperrt { position: absolute; right: 0; top: 0; color: #848386; font-size: 16px; }
.artikel_liste .eintrag_erfassen { position: absolute; right: 15px; top: 10px; }
.artikel_liste .eintrag_erfassen i { color: #e30613; margin-left: 5px; }

/*** Tab - Allgemein ***/
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active { color: #e30613; background-color: #fff; border-color: transparent; }
.nav-tabs .nav-link { border: 0 none; border-top-left-radius: 0; border-top-right-radius: 0; font-family: 'Roboto-Medium'; text-transform: uppercase; background: #e3e3e3; text-align: center; padding: 20px 0; color: #5a5a5a }
.nav-tabs .nav-item { margin-bottom: -1px; width: 50%; }
.nav-tabs { border-bottom: 0px none; border-top: 2px solid #e30613; border-left: 2px solid #e3e3e3; border-right: 2px solid #e3e3e3; }
.tab-content { border-bottom: 2px solid #e3e3e3; border-left: 2px solid #e3e3e3; border-right: 2px solid #e3e3e3; padding: 30px 30px 25px 30px; }
.tab-content li { list-style: none; border-bottom: 1px solid #dadada; padding-bottom: 10px; margin-bottom: 10px; }

/*** Suche ***/
.suche_liste { }
.suche_liste article { border-bottom: 1px solid #dadada; padding-bottom: 20px; margin-bottom: 20px; margin-top: 10px; }
.suche_liste article .datum { color: #999; }

/*** Modal - Hilfe ***/
#modal_hilfe table td, #modal_hilfe table th { border-bottom: 1px solid #dadada; padding: 10px 20px 10px 0; vertical-align: top; }

/*** Lageplan ***/
.map_canvas { width: 100%; height: 500px; }

/*** Suchmaske ***/
.suchmaske { background: #e3e3e3; padding: 30px; }
.suchmaske .form-control { width:300px; padding: 10px; font-size: 16px; line-height: 24px; color: #999; background-color: #fff; border: 0 none; border-radius: 0; }
.suchmaske button { background: #e30613; color: #fff; padding: 10px; font-size: 16px; margin-top: 0; border: 0 none; }
.suchmaske button:hover { background: #5a5a5a; }
.anzahl_resultate { margin-top: 20px; }
.form-inline .hilfe { margin-top: 10px; color: #999; cursor: help; width:100%; }

/*** Fotogalerie ****/
.fotogalerie { }
.fotogalerie .item { margin-bottom: 20px; }
.fotogalerie .item img:hover { cursor: zoom-in; opacity: 0.8; }
.fotogalerie .item .bildlegende { color: #666; font-size: 16px; margin-top: 5px; }

/*** Amtliches / Stellen ***/
#amtliches_stellen_content .titel { font-family: 'Roboto-Medium'; font-size: 16px; }
#amtliches_stellen_content .ort { text-transform: uppercase; color: #999; font-size: 13px; }

/*** Stellen - Liste ***/
.stellen_liste { }
.stellen_liste article { border-bottom: 1px solid #dadada; padding: 15px 0; position:relative; }
.stellen_liste article h3 { padding-right:30px; }
.stellen_liste article .ort { margin-right: 20px; color: #999; }
.stellen_liste article .datum { color: #999; }
.stellen_liste article .firma { color: #999; margin-right: 20px; }
.stellen_liste article .weiter { font-size: 20px; color: #e30613; position:absolute; right:0; top:16px; }

/*** Amtliches - Liste ***/
.amtliches_liste article { border-bottom: 1px solid #dadada; padding: 15px 0; }
.amtliches_liste .anzahl_resultate { padding-bottom: 20px; }
.amtliches_liste .suchresultate { border-top: 1px solid #dadada; }
.amtliches_liste article .ort { margin-right: 20px; color: #999; margin-top: 5px; }
.amtliches_liste article .datum { color: #999; margin-top: 5px; }
/*** Google Map ***/
.map_canvas { width: 100%; height: 400px; }

/*** Abo Pakete ***/
.abo_pakete {}
.abo_pakete .item {background:#e3e3e3;padding:30px;}
.abo_pakete .item h3 {font-size: 25px; line-height:32px; text-transform: uppercase; font-family: 'Roboto-Medium'; text-align: center; color:#333; width: 100%;}
.abo_pakete .item .foto { width: 100%; text-align: center;}
.abo_pakete .item .text {}
.abo_pakete .item .text p { font-size: 16px; line-height: 23px;}
.abo_pakete .item .text ul {border-top:1px solid #e30613; margin: 20px 0;}
.abo_pakete .item .text ul li { border-bottom:1px solid #e30613; padding:15px 0; list-style: none;margin-left: 0px; font-size: 18px; line-height: 25px; padding-left: 30px}
.abo_pakete .item .text ul li:before {padding: 0;content: "\f058";font-family: "Font Awesome 5 Pro";font-size: 24px;color: #e30613;font-weight: 200;font-style: normal;display: inline-block;text-rendering: auto;-webkit-font-smoothing: antialiased;margin-left: -30px;width: 30px; line-height: 32px; }
.abo_pakete .item .form-check {font-size: 16px; line-height: 23px; margin-bottom: 5px;}
.abo_pakete .hilfetext p {font-size: 12px; line-height: 19px;color:#999;}
.schon_abonnent { border-top:1px solid #e30613; padding-top:10px; padding-bottom:10px;border-bottom:1px solid #e30613;  font-family: 'Roboto-Medium'; font-size: 25px;line-height: 32px; color:#333;}
.schon_abonnent .btn {  margin-top: 0;}


/*** Gewähltes Abo ***/
.gewaehltes_abo { border:2px solid #e3e3e3; padding:20px 30px; margin-bottom: 30px;
                ul { margin-left:20px; margin-top:5px;
                  li { margin-bottom: 5px;}
                }
                .preis {font-size: 24px; line-height:31px; margin: 10px 0; }
}

/*** Abo - Bestellen ***/
.profil.abobestellen {
                     
}

/*** Passwort zurücksetzen ***/
.passwort_zuruecksetzen {
                         ol {margin-block-start: 0;margin-block-end: 0; padding-inline-start: 20px;
                           li { margin: 10px;
                             &::marker { font-family: 'Roboto-Medium';color: #e30613 }
                           }
                         } 
                         input:hover, input:focus { border: 1px solid #e3e3e3; } 
}

/*** Abo-Zusammenfassung ***/
.profil.abozusammenfassung { 
                            ol { margin-left: 20px;
                                li {margin: 5px 0;}
                              }
}

/*** Meistgelesen ***/
.meistgelesen li { padding-bottom: 20px; margin-bottom: 20px; font-family: 'Roboto-Medium'; font-size: 18px; list-style: decimal; margin-left: 20px; color: #e30613; }
.meistgelesen li span { color: #5a5a5a !important; font-family: 'Roboto-Regular'; }
.meistgelesen li:last-child { border-bottom: 0 none; padding-bottom: 0; margin-bottom: 15px; }
.meistgelesen li:hover span { color: #e30613 !important }

/*** Newsticker ***/
.newsticker li { font-size: 16px; }
.newsticker li .datum { color: #999; font-size: 13px; }

/*** Agenda ***/
#agenda_tab { text-align: left; padding-left: 30px; border-bottom: 1px solid #dadada }
.agenda li { }
.agenda li .datum, .agenda li .ort { color: #999; font-size: 13px; }
.agenda li.premium .titel { font-family: 'Roboto-Medium'; font-size: 16px; }
.agenda li.free { color: #999; }
#tab_agenda { border-bottom: 1px solid #dadada }

/*** Agenda - Liste ***/
.agenda_liste .suchmaske .form-control { width: 280px; margin-right: 20px; }
.agenda_liste .suchmaske .form-control[name=daterange] { width: 200px; margin-right: 0; }
.agenda_liste article { border-bottom: 1px solid #dadada; padding: 15px 0; }
.agenda_liste article .ort { margin-right: 20px; color: #999; }
.agenda_liste article .datum { color: #999; }
.agenda_liste article .firma { color: #999; margin-right: 20px; }
.agenda_liste article .weiter { font-size: 20px; color: #e30613 }
.agenda_liste .suchresultate { border-top: 1px solid #dadada; margin-right: 0; margin-left: 0; }
.agenda_liste .anzahl_resultate { padding-bottom: 15px; }
.agenda_liste .eintrag_erfassen { position: absolute; right: 15px; top: 10px; }
.agenda_liste .eintrag_erfassen i { color: #e30613; margin-left: 5px; }
.agenda_liste article .datum { margin-top: -3px; }
.agenda_liste article.premium h2 { line-height: 35px; margin-bottom: 0px; }
.agenda_liste article.standard { }
.agenda_liste article.standard h2 { font-size: 16px; font-family: 'Roboto-Regular'; font-weight: normal; line-height: 23px; }
.agenda_liste article:hover p, .agenda_liste article:hover .ort, .agenda_liste article:hover .datum, .agenda_liste article:hover h2 { color: #e30613; }
.agenda_liste article .ort { margin-top: 5px; }

/*** E-Paper ***/
.epaper_box { background: url("../img/epaper_hg.jpg") no-repeat; width: 100%; min-height: 250px; position: relative; }
.epaper_box .text { background: #e30613; color: #fff; font-family: 'Roboto-Light'; font-size: 20px; text-transform: uppercase; padding: 3px 10px; }
.epaper_box .text a { color: #fff; }
.epaper_box .text strong { font-family: 'Roboto-Medium'; }
.epaper_box .box1 { position: absolute; right: 0; top: 15px; }
.epaper_box .box2 { position: absolute; right: 0; top: 60px; }
.epaper_box .box3 { position: absolute; left: 0; bottom: 20px; }
.epaper.browser {
                .suchmaske {
                           .form-control { padding:10px; height: 50px; width:100%; margin-bottom: 0 !important;}
                           .btn {height: 50px;}
                }
                .item { margin-bottom: 30px;
                      .label { margin-top: 10px; font-size: 16px; line-height: 23px;}  
                      img:hover {curser: pointer; opacity: 0.9;}
                }      
}
.epaper_suche {
              .suchmaske {
                          .form-control { padding:10px; height: 50px; width:200px;}
                          .btn {height: 50px;}
              }            
              .item { border-bottom:1px solid #efefef; margin-bottom: 20px; padding-bottom:20px;
                    .edition, .page { display: block;background:#e30613;color:#fff; padding:10px; margin-bottom: 10px;
                                    a {color:#fff; display: block;}
                                    &:hover {background: #000;}
                    }
                    .mp-search-hl { background: #ffe8a1; padding:0 3px;}
                    img {-webkit-box-shadow: 2px 2px 5px 0 rgb(50 50 50 / 30%);-moz-box-shadow: 2px 2px 5px 0 rgba(50,50,50,.3);box-shadow: 2px 2px 5px 0 rgb(50 50 50 / 30%);}
              }
}

/*** Inserate Klein ***/
.inserate_klein {margin-bottom:50px;}
.inserate_klein .carousel-indicators { position: relative; right: auto; bottom: auto; left: auto; z-index: 15; padding-left: 0; margin-right: 15%; margin-left: 15%; list-style: none; margin-top: 10px; }
.inserate_klein .carousel-indicators li { width: 15px; height: 15px; margin-right: 3px; margin-left: 3px; text-indent: -999px; background-color: #e3e3e3; }
.inserate_klein .carousel-indicators li.active { background-color: #e30613 }
.inserate_klein .carousel-indicators li:hover { background-color: #e30613; cursor: pointer; }
.inserate_klein .carousel {text-align: center;}
.inserate_klein .carousel-control-next, .inserate_klein .carousel-control-prev {position: relative;top:auto;bottom: auto; height:30px;margin-top:10px;display: -webkit-box; display: -ms-flexbox;display: inline-block;width:30px;color: #fff;text-align: center;opacity: 1; background: #333; font-size: 20px;}
.inserate_klein .carousel-control-next {left:auto; right:auto;}
.inserate_klein .carousel-control-next:hover, .inserate_klein .carousel-control-prev:hover { background:#e30613;}

/*** Newsletter ***/
.newsletter_box { padding: 30px; background: url("../img/newsletter_hg.png") no-repeat bottom left #e3e3e3 }
.newsletter_box h4 { margin-bottom: 10px; }
.newsletter_box .email_icon { background: #fff; color: #666; padding: 10px }
.newsletter_box .form-group { display: block; width: 100%; padding: 5px; font-size: 14px; line-height: 14px; color: #666; background-color: #fff; border: 1px solid #fff; border-radius: 0; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
.newsletter_box .form-group input { border: 0 none; padding: 7px; width: 80%; }
.newsletter_box form { margin-top: 15px; }
.newsletter_box .form-group input:focus { outline: -webkit-focus-ring-color auto 0px }
.newsletter_box button { background: #e30613; color: #fff; padding: 10px 15px; border: 0 none; }
.newsletter_box button:hover { background: #5a5a5a; cursor: pointer }

/*** Footer ***/

/** Inserate gross - Carousel **/
.inserat_caroussel_gross { width: 100%; position: relative; overflow: hidden; background: #e3e3e3; padding: 30px 0; margin: 0; }
.jcarousel { position: relative; }
.jcarousel ul { width: 20000em; position: relative; list-style: none; margin: 0; padding: 0; height: 100%; }
.jcarousel li { float: left; margin-left: 50px; margin-right: 50px; opacity: 0.3; line-height: 400px; }
.jcarousel li img { margin: 0 auto; border: 3px solid rgba(255, 255, 245, 1); -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; -webkit-box-shadow: 0 0 2px #999; -moz-box-shadow: 0 0 2px #999; box-shadow: 0 0 2px #999; }
.jcarousel li img:hover { cursor: zoom-in; }
.jcarousel-control-prev, .jcarousel-control-next { position: absolute; top: 180px; width: 40px; height: 40px; line-height: 35px; z-index: 100; text-align: center; background: #e30613; color: #fff !important; text-decoration: none; text-shadow: none; font-size: 30px; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; -webkit-box-shadow: 0 0 2px #999; -moz-box-shadow: 0 0 2px #999; box-shadow: 0 0 2px #999; cursor: pointer; }
.jcarousel-control-prev { left: 1px; }
.jcarousel-control-next { right: -90px; }
.jcarousel .Image_Wrapper { height: 100%; }
.jcarousel-control-prev:hover span, .jcarousel-control-next:hover span { display: block; }
.jcarousel-control-prev:hover, .jcarousel-control-next:hover { background: #5a5a5a }
.jcarousel-wrapper { margin: 0 auto; position: relative; width: 400px; height: 250px; }
.jcarousel .caroussel_box { width: 385px; text-align: center; height: 100%; }
.jcarousel .toolbar { text-align: center; font-size: 22px; margin-top: 5px; }

/*** Bildgalerien ***/
.bildgalerien { width:100%; position: relative; overflow: hidden; padding: 30px 60px 40px 60px; margin: 0; background: url("../img/bildgalerien_hg.png") no-repeat left bottom #e3e3e3; }
.bildgalerien article { position: relative; overflow: hidden; }
.bildgalerien article .schatten { position: absolute; left: 0; width: 100%; top: 0; bottom: 0; background: url("../img/galerie_schatten.png") repeat-x bottom; z-index: 5; }
.bildgalerien article .text { position: absolute; z-index: 6; bottom: 10px; color: #fff; padding: 0 20px; }
.bildgalerien h2 { font-family: 'Roboto-Medium'; font-size: 22px; font-weight: normal; margin-bottom: 10px; line-height: 29px; }
.bildgalerien article img { -webkit-transform: scale(1); transform: scale(1); -webkit-transition: .3s ease-in-out; transition: .3s ease-in-out; }
.bildgalerien article:hover img { -webkit-transform: scale(1.1); transform: scale(1.1); }

/*** Dossiers ***/
.dossiers { }
.dossiers article { padding-bottom: 30px; margin-bottom: 30px; margin-top: 10px; }
.dossiers article .text { position: relative; margin-top: 20px; }
.dossiers article .thema { color: #e30613; text-transform: uppercase; margin-top: -5px; margin-bottom: 5px; }
.dossiers article .multimedia_icons { position: absolute; bottom: 0; width: 100%; }
.dossiers article .icon { background: #e30613; color: #fff; padding: 8px 10px; margin-left: 5px; }
.dossiers article .foto, .artikel_liste article .text { position: relative; }
.dossiers article .gesperrt { position: absolute; right: 0; top: 0; color: #848386; font-size: 16px; }

/*** Kolumnen ***/
.kolumnen article { position: relative; margin-bottom: 30px; }
.kolumnen article .titel { position: absolute; bottom: 0; right: 0; background: #e30613; color: #fff; padding: 5px 10px; font-size: 18px; text-transform: uppercase; }
.kolumnen_liste article { position: relative; margin: 30px 0; }
.kolumnen_liste article .titel { position: absolute; bottom: 0; right: 15px; background: #e30613; color: #fff; padding: 5px 10px; font-size: 18px; text-transform: uppercase; }
.kolumnen_liste article:hover, .kolumnen article:hover { opacity: 0.8; }

/*** Folgen Sie uns ***/
.folgen_box { padding: 30px; background: url("../img/folgen_hg.png") no-repeat bottom left #e3e3e3 }
.folgen_box ul { list-style: none; }
.folgen_box ul li { color: #fff; padding: 20px; font-size: 16px; margin-bottom: 20px; }
.folgen_box ul li a { color: #fff; display: block; }
.folgen_box ul li.facebook { background: #3c5a99; }
.folgen_box ul li.twitter { background: #419aff; }
.folgen_box ul li.instagram { background: #e1306c }
.folgen_box ul li i { font-size: 24px; padding-right: 10px; border-right: 1px solid #fff; margin-right: 10px; width: 35px; }
.folgen_box ul li:hover { background: #333 !important; }

/*** Paging ***/
.page-link { padding: .5rem .75rem; margin-left: 0px; line-height: 1.25; background-color: #f3f3f4; border: 2px solid #f3f3f4; color: #5a5a5a; margin-right: 5px; }
.page-item.active .page-link { z-index: 1; color: #fff; background-color: #f3f3f4; border: 2px solid #f3f3f4; border-top: 2px solid #e30613; color: #5a5a5a; }
.page-link:hover { color: #fff; text-decoration: none; background-color: #e30613; border-color: #e30613; }
.page-item.disabled .page-link { display: none;}
.page-item:first-child .page-link { border-top-left-radius: 0; border-bottom-left-radius: 0; }
.page-item:last-child .page-link { border-top-right-radius: 0; border-bottom-right-radius: 0; }

/*** Artikel - Detail ***/
.artikel_detail h1 { font-size: 48px; line-height: 55px; font-family: 'Roboto-Medium'; font-weight: normal; text-transform: none; margin-bottom: 20px; }
.artikel_detail .lead p { font-size: 20px; font-family: 'Roboto-Regular'; font-weight: normal; line-height: 27px; }
.artikel_detail .lead { margin-bottom: 30px; }
.artikel_detail .autor, .artikel_detail .datum { font-family: 'Roboto-Light'; font-size: 16px; }
.artikel_detail .autor strong { font-family: 'Roboto-Regular'; font-size: 16px; line-height: 23px; }
.artikel_detail .datum { color: #8f8e91; }
.artikel_detail .social_media_share { margin-top: -5px; }
.artikel_detail .social_media_share i { color: #8f8e91; font-size: 16px; margin-left: 15px; }
.artikel_detail .social_media_share i:hover { color: #333; cursor: pointer; }
.artikel_detail .bildlegende { border-bottom: 1px solid #dadada; padding-bottom: 10px; padding-top: 15px; margin-bottom: 20px; color: #5a5a5a; height:100px; }
.artikel_detail .haupttext > p { font-size: 18px; line-height: 28px; font-family: 'Roboto-Light'; font-weight: normal; }
.artikel_detail .haupttext a {color:#e30613;}
.artikel_detail .haupttext a:hover {color:#5a5a5a;}
.artikel_detail .thema { font-family: 'Roboto-Medium'; font-size: 16px; text-transform: uppercase; margin-bottom: 10px; }
.artikel_detail .carousel-control-next, .artikel_detail .carousel-control-prev { position: absolute; top: 45%; margin-top: -56px; height: 50px; bottom: auto; width: 40px; color: #5a5a5a; text-align: center; opacity: 1; background: #fff; font-size: 40px; }
.artikel_detail .carousel-control-next:hover { background: #e30613; color: #fff; }
.artikel_detail .carousel-control-prev:hover { background: #e30613; color: #fff; }
.artikel_detail .carousel-item img:hover, .artikel_detail .zusatzbox img:hover { cursor: zoom-in }
.artikel_detail .zusatzbox { background: #e3e3e3; padding: 20px; margin: 30px 0; }
.artikel_detail .zusatzbox p { font-size: 18px; line-height: 28px; font-family: 'Roboto-Light'; font-weight: normal; }
.artikel_detail .zusatzbox a {color:#e30613;}
.artikel_detail .zusatzbox a:hover {color:#5a5a5a;}
.artikel_detail .zitat { font-size: 20px; font-family: 'Roboto-Regular'; font-weight: normal; line-height: 27px; position: relative; padding-left: 25px; margin-top: 30px; display: block; }
.artikel_detail .zitat:before { content: "\f100"; font-family: FontAwesome; font-style: normal; font-weight: normal; text-decoration: inherit; color: #999; font-size: 30px; position: absolute; top: 0px; left: 0; }
.artikel_detail .zitat:after { content: "\f101"; font-family: FontAwesome; font-style: normal; font-weight: normal; text-decoration: inherit; color: #999; font-size: 30px; margin-left: 10px; }
.artikel_detail .image_background { background: #e3e3e3; text-align: center;}

/*** Lesenswert ***/
.lesenswert { border: 1px solid #e3e3e3; padding: 20px; margin-top:30px; }
.lesenswert .frage { font-size: 25px; font-weight: normal; line-height: 32px; float: left; }
.lesenswert .anwort { float: right; }
.lesenswert input.btn { margin-top: 0px !important; }
.lesenswert input.btn:hover { background: #5a5a5a }

/*** Formular ***/
.form-horizontal .form-group { margin-bottom: 10px; margin-left: -15px; margin-right: -15px; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.form-horizontal input, .form-horizontal textarea { border: 1px solid #e3e3e3; padding: 5px; width: 400px; }
.form-horizontal textarea { height: 200px; }
.form-horizontal input[type="checkbox"] { width: 20px; display: inline-block;}
.form-horizontal .radio input { margin-right: 8px; border: none; padding: 0; width: auto; }
.form-horizontal h2 { font-family: 'Roboto-Medium'; font-size: 16px; text-transform: uppercase; border-bottom: 1px solid #dadada; text-transform: uppercase; }
.form-horizontal input.btn { border: 0 none; padding: 5px 10px; width: auto; background: #e30613; color: #fff; border-radius: 0; margin-top: 10px; font-size: 16px; }
.help-block { font-size: 12px; display: inline-block; }

/*** Bildgalerien ***/
.bildgalerie_liste article { border-bottom: 1px solid #dadada; padding-bottom: 30px; margin-bottom: 30px; margin-top: 10px; }
.bildgalerie_liste article h2 { margin-top: -7px; }
.bildgalerie_detail .item { margin-bottom: 30px; }
.bildgalerie_detail .item:hover { opacity: 0.8; cursor: zoom-in; }
.bildgalerie_detail .lightgallery { margin-top: 30px; }

/*** Trauerzirkulare ***/
.trauerzirkulare .nav-tabs .nav-item { width: 25%; }
.trauerzirkulare .designauswahl .item { margin-bottom: 15px; position: relative; }
.trauerzirkulare .designauswahl .item .text { position: absolute; left: 45%; top: 40%; display: none; color: #e30613; font-size: 45px; }
.trauerzirkulare .designauswahl .item:hover .text { display: block; }
.trauerzirkulare .designauswahl .item img { margin-bottom: 5px; }
.trauerzirkulare .texte { }
.trauerzirkulare .texte img { margin-bottom: 5px; }
.trauerzirkulare .form-control { width: 100%; padding: 10px; font-size: 14px; line-height: 20px; color: #666; border: 1px solid #e3e3e3; border-radius: 0; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
.trauerzirkulare textarea.form-control { height: 80px; }
.trauerzirkulare label { font-family: 'OpenSans-Semibold'; }
.trauerzirkulare textarea.textarea_gross { height: 150px; }
#textVonTag, #textVonJahr, #textBisTag, #textBisJahr { width: 50px; margin-bottom: 20px; }
#textVonMonat, #textBisMonat { width: 120px; }

/*** Danksagungen ***/
.danksagungen .nav-tabs .nav-item { width: 25%; }
.danksagungen .designauswahl { margin-top: 20px; }
.danksagungen .designauswahl .item { margin-bottom: 15px; position: relative; }
.danksagungen .designauswahl .item .text { position: absolute; left: 45%; top: 20%; display: none; color: #e30613; font-size: 45px; }
.danksagungen .designauswahl .item:hover .text { display: block; }
.danksagungen .designauswahl .item img { margin-bottom: 5px; }
.danksagungen .texte { }
.danksagungen .texte img { margin-bottom: 5px; }
.danksagungen .form-control { width: 100%; padding: 10px; font-size: 14px; line-height: 20px; color: #666; border: 1px solid #e3e3e3; border-radius: 0; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
.danksagungen textarea.form-control { height: 80px; }
.danksagungen label { font-family: 'OpenSans-Semibold'; }
.danksagungen textarea.textarea_gross { height: 150px; }

/*** Registrationsformular ***/
#registration-form input:-webkit-autofill { border: 1px solid #e3e3e3; padding: 5px; width: 400px; -webkit-box-shadow: none }
#registration-form .form-control { border: 1px solid #e3e3e3; padding: 5px; width: 400px; border-radius: 0; }
#registration-form .form-control.input-validation-error { border: 1px solid #a94442}
.validation-summary-errors ul{padding-left: 20px;}

#registration-form .form-group { margin-bottom: 10px; margin-left: -15px; margin-right: -15px; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }
#registration-form input, .form-horizontal textarea { border: 1px solid #e3e3e3; padding: 5px; width: 400px; }
#registration-form textarea { height: 200px; }
#registration-form .radio input { margin-right: 8px; border: none; padding: 0; width: auto; }
#registration-form h2 { font-family: 'Roboto-Medium'; font-size: 16px; text-transform: uppercase; border-bottom: 1px solid #dadada; text-transform: uppercase; }
#registration-form input.btn { border: 0 none; padding: 5px 10px; width: auto; background: #e30613; color: #fff; border-radius: 0; margin-top: 10px; font-size: 16px; }

/*** Login ***/
.login .form-control { border: 1px solid #e3e3e3; padding: 5px; width: 400px; border-radius: 0; }

/*** Umfragen ***/
.umfrage_box {}
.umfrage_box .form-horizontal {margin-top:0px;}
.umfrage_box h4 { display: none;}
.umfrage_box h2 { text-transform: none; border: none; font-size:16px; font-weight: normal; }
.umfrage_box .datum { color:#333; font-size:14px;}
.umfrage_box .poll_title p{ font-size:22px; line-height: 30px;}
.umfrage_box .form-horizontal input[type="radio"] { width:6%; vertical-align: top; margin-top: 3px;}
.umfrage_box .poll_question_item { margin: 7px 0; }
.umfrage_box .form-horizontal .btn.btn-default {margin-left:0;}
.umfrage_box .poll_box_question { width: 100% !important;}
.umfrage_box .poll_Bars {background: transparent; }
.umfrage_box .poll_Bars .bar {height:10px;padding: 2px 0;  border: 0 none; margin:0; background: #e30613}
.umfrage_box .poll_Bars .label {font-size: 14px;}
.umfrage_box .poll_Bars .prozent { font-size: 14px; margin-top: 2px;}
.umfrage_box .bar_box { width: 100%; height: 10px;display: block; margin-bottom: 15px; background:#dcddd8;}
.umfrage_box .poll_Bars { margin-top: 20px;}
.umfrage_box .umfrage { background: #e3e3e3; padding: 20px; border-top: 5px solid #e30613; }
.umfrage_box .umfrage .frage {  margin-bottom: 10px; }
.umfrage_box .umfrage label { font-size: 16px; width: 90%}
.umfrage_box .link {     line-height: 40px;}
.form-horizontal .has-image input {width: 20px; position: absolute; top:10px; left:20px;}

/*** Umfragenliste ***/
.umfragen_liste h2 { text-transform: uppercase; border: none; font-size:18px; line-height: 30px; font-weight: normal;}
.umfragen_liste .poll_title p { font-size:22px; line-height: 30px;}
.umfragen_liste .form-horizontal input[type="radio"] { width: auto;}
.umfragen_liste .poll_question_item { margin: 7px 0;}
.umfragen_liste .form-horizontal .btn.btn-default {margin-left:0;}
.umfragen_liste .poll_box_question { width: 100% !important;}
.umfragen_liste .poll_Bars {background: transparent; }
.umfragen_liste .poll_Bars .bar {height:10px;padding: 2px 0; margin-bottom:0; border: 0 none; margin:0; background:#e30613;}
.umfragen_liste .poll_Bars .label {font-size: 14px;}
.umfragen_liste .poll_Bars .prozent { font-size: 14px;}
.umfragen_liste .bar_box {; width: 100%; height: 10px;display: block; margin-bottom: 15px; background:#dcddd8; }
.umfragen_liste .poll_Bars { margin-top: 20px;}
.umfragen_liste .poll_box_question { margin-bottom:50px; background: #e3e3e3; padding:20px 30px;}

.artikel_detail .form-horizontal {margin-top:0px; margin-bottom: 20px;}
.artikel_detail .poll_box_question h4 { display: none;}
.artikel_detail .poll_box_question h2 { text-transform: none; border: none; font-size:16px; font-weight: normal; }
.artikel_detail .poll_box_question {background: #e3e3e3;padding: 20px;}
.artikel_detail .poll_box_question .datum { color:#999; font-size:14px;}
.artikel_detail .poll_box_question .poll_title p{ font-size:22px; line-height: 30px; }
.artikel_detail .poll_box_question  .form-horizontal input[type="radio"] { width:6% !important; vertical-align: top; margin-top: 0px;}
.artikel_detail .poll_box_question  .poll_question_item { margin: 7px 0; }
.artikel_detail .poll_box_question  .form-horizontal .btn.btn-default {margin-left:0;}
.artikel_detail .poll_box_question  .poll_box_question { width: 100% !important;}
.artikel_detail .poll_box_question  .poll_Bars {background: transparent; }
.artikel_detail .poll_box_question  .poll_Bars .bar {height:10px;padding: 2px 0; margin-bottom:0; border: 0 none; margin:0; background: #e30613}
.artikel_detail .poll_box_question .poll_Bars .label {font-size: 14px;}
.artikel_detail .poll_box_question  .poll_Bars .prozent { font-size: 14px; margin-top: 2px;}
.artikel_detail .poll_box_question .bar_box { width: 100%; height: 10px;display: block; margin-bottom: 15px; background:#e3e3e3;}
.artikel_detail .poll_box_question  .poll_Bars { margin-top: 20px;}
.artikel_detail .poll_box_question  .umfrage { background:#efefef; padding: 20px; border-top: 5px solid #e30613; }
.artikel_detail .poll_box_question  .umfrage .frage {  margin-bottom: 10px; }
.artikel_detail .poll_box_question  .umfrage label { font-size: 16px; width: 90%}
.artikel_detail .poll_box_question  .link {     line-height: 40px;}
.artikel_detail .poll_box_question input, .artikel_detail .poll_box_question textarea { width:20px !important; vertical-align: top; margin-top: 3px;}
.artikel_detail .form-horizontal .poll_box_question .btn.btn-default {background: #e30613;color: #fff;width: auto !important;margin-left: 0;padding: 10px 15px;}


/*** Footer ***/
footer { margin-top: 60px; }
footer ul { list-style: none; }
footer li { text-transform: uppercase; margin-bottom: 3px; }
footer li.titel { font-family: 'OpenSans-Semibold'; border-top: 2px solid #5a5a5a; border-bottom: 1px solid #5a5a5a; padding: 7px 0px; margin-bottom: 8px; }
footer .logo { margin-bottom: 50px; }


/*** In Bearbeitung ***/
.inbearbeitung { background: url("../img/footer_foto.jpg") no-repeat bottom center;-webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover; background-size: cover;height: 100%; position: absolute; top: 0; width: 100%; padding-top: 60px;background-position: 0px 100px;}
.inbearbeitung .text { font-size: 18px; line-height: 26px;}

/*** Team ***/
.team h2 { font-family: 'Roboto-Medium'; font-size: 16px; font-weight: normal; margin: 0; padding: 0; text-transform: uppercase; line-height: 18px; }
.team .abteilung { margin-top: 20px; }
.team .email a { color: #999; }
.team .email a:hover { color: #e30613; }
.team img { margin-bottom: 5px; }

/*** Abo - Zeitung ***/
.abo_box { position: relative; height: 150px; margin-top: 40px; }
.abo_box .zeitung { position: absolute; left: -150px; }
.abo_box .text { font-size: 30px; font-family: 'Roboto-Light'; text-transform: uppercase; margin-top: 60px; }
.abo_box .text strong { font-family: 'Roboto-Medium'; }

/*** Nach oben ***/
.nach_oben { text-transform: uppercase; font-family: 'Roboto-Medium'; margin-top: 70px; }
.nach_oben span { display: inline-block !important}
.nach_oben i { background: #e30613; color: #fff; font-size: 25px; width: 30px; height: 30px; line-height: 30px; text-align: center; margin-left: 5px; }
.nach_oben:hover i { background: #333; }

/*** Footer - Adresse ***/
.footer_adresse { margin-top: 30px; margin-bottom: 45%; color:#fff; }
.footer_adresse a{color:#fff; }
.footer_adresse p {color:#fff; }

/*** Footer - Bild ***/
.box_schatten { -webkit-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.42); -moz-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.42); box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.42); }
.footer_foto { background: url("../img/footer_foto.jpg") no-repeat top center; height: 70%; position: absolute; bottom: 0; width: 100%; }

/*** Überschriften ***/
h1 { font-family: 'Roboto-Light'; font-size: 32px; font-weight: normal; padding: 0; margin: 0 0 5px 0; line-height: 40px; text-transform: uppercase; }
h2 { font-family: 'Roboto-Medium'; font-size: 28px; font-weight: normal; padding: 0; margin: 0 0 5px 0; line-height: 35px; }
h3 { font-family: 'Roboto-Medium'; font-size: 24px; font-weight: normal; padding: 0; margin: 0 0 5px 0; line-height: 31px; }
h4 { font-family: 'Roboto-Medium'; font-size: 16px; text-transform: uppercase; margin: 0 0 20px 0; font-weight: normal; line-height: 23px; }
h5 { font-family: 'Roboto-Light'; font-size: 22px; font-weight: normal; padding: 0; margin: 10px 0 0px 0; line-height: 30px;  }
h6 { font-family: 'Roboto-Light'; font-size: 18px; font-weight: normal; padding: 0; margin: 5px 0 0px 0; line-height: 25px;  }
.titel_rot { color: #e30613; font-size: 32px; line-height: 40px; }



@import 'responsive';


