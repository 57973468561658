

/*** Roboto (Titel) ***/
@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto-Light.eot');
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Light.woff2') format('woff2'),
    url('../fonts/Roboto-Light.woff') format('woff'),
    url('../fonts/Roboto-Light.ttf') format('truetype'),
    url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../fonts/Roboto-Medium.eot');
    src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Medium.woff2') format('woff2'),
    url('../fonts/Roboto-Medium.woff') format('woff'),
    url('../fonts/Roboto-Medium.ttf') format('truetype'),
    url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../fonts/Roboto-Bold.eot');
    src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/Roboto-Bold.woff') format('woff'),
    url('../fonts/Roboto-Bold.ttf') format('truetype'),
    url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Italic';
    src: url('../fonts/Roboto-Italic.eot');
    src: url('../fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Italic.woff2') format('woff2'),
    url('../fonts/Roboto-Italic.woff') format('woff'),
    url('../fonts/Roboto-Italic.ttf') format('truetype'),
    url('../fonts/Roboto-Italic.svg#Roboto-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}


/*** Open Sans (Fliesstext) ***/
@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans.eot');
    src: url('../fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans.woff2') format('woff2'),
    url('../fonts/OpenSans.woff') format('woff'),
    url('../fonts/OpenSans.ttf') format('truetype'),
    url('../fonts/OpenSans.svg#OpenSans') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'OpenSans-Italic';
    src: url('../fonts/OpenSans-Italic.eot');
    src: url('../fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Italic.woff2') format('woff2'),
    url('../fonts/OpenSans-Italic.woff') format('woff'),
    url('../fonts/OpenSans-Italic.ttf') format('truetype'),
    url('../fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Semibold';
    src: url('../fonts/OpenSans-Semibold.eot');
    src: url('../fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Semibold.woff2') format('woff2'),
    url('../fonts/OpenSans-Semibold.woff') format('woff'),
    url('../fonts/OpenSans-Semibold.ttf') format('truetype'),
    url('../fonts/OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/OpenSans-Bold.woff') format('woff'),
    url('../fonts/OpenSans-Bold.ttf') format('truetype'),
    url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'OpenSans-Light';
    src: url('../fonts/OpenSans-Light.eot');
    src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Light.woff2') format('woff2'),
    url('../fonts/OpenSans-Light.woff') format('woff'),
    url('../fonts/OpenSans-Light.ttf') format('truetype'),
    url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}


