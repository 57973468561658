

/******* Media Queries **********/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
  /*** Basis ***/
  .container { padding: 0 15px; }
  .row.full { margin-left: -15px; margin-right: -15px; }

  /*** Footer ***/
  .abo_box .zeitung { left: -70px; }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
  /*** Header ***/
  .anmeldung { text-align: left; }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  /*** Rechte Spalte ***/
  .rechte_spalte .abstand_oben_40 { margin-top:0;}

  /*** Header ***/
  header .logo { margin-bottom: 15px; margin-top: 15px; }

  /*** ePaper - Button ***/
  .epaper_mobile { position: absolute; left:75px; font-size: 20px;}
  .epaper_mobile a {color:#e30613;}

  /*** Artikel ***/
  .artikel_liste article .thema { margin-top:10px; }

  /*** Bildgalerien ***/
  .bildgalerien { padding: 30px 15px 40px 15px; }
  .bildgalerien article { margin-top: 10px; }

  /*** Hauptnavigation ***/
  .navigation_box { margin-top: 0; }
  .navigation_box .suche { font-size: 28px; line-height: 28px; margin-top: 5px; }
  #navbarCollapse li { margin-right: 0; }
  .navigation_box .anmeldung { margin-right: 15px; }
  .navigation_box .nav-item { display: block; text-align: center; margin-bottom: 3px; line-height: 40px; background-color: #e3e3e3; }
  .navbar-collapse { position: absolute; right: -15px; top: 40px; left: -15px; background-color: #fff; z-index: 100; padding: 10px 15px 15px 10px; border-bottom: 1px solid #848386; }
  #navbarCollapse .dropdown-menu { padding: 0; margin: 0; }
  #navbarCollapse .dropdown-menu li { margin: 0; padding: 0; text-align: center; }
  #navbarCollapse .dropdown-menu a { color: #5a5a5a; }

  /** Inserate gross - Carousel **/
  .jcarousel-wrapper { width: 320px; }
  .jcarousel .caroussel_box { width: 320px;  }
  .jcarousel li { margin-left:0; margin-right:0; }
  .jcarousel-control-next { right:0; }

  /*** Topnews ***/
  #topnews_carousel .carousel-control-next, #topnews_carousel .carousel-control-prev { top: 0; margin-top: 80px; }
  #topnews_carousel { min-height:unset; border-top:0!important; }

  /*** Navigationspunkt ***/
  .navigationspunkt table td { display:block; width:100%!important; }

  /*** Footer ***/
  .abo_box .zeitung { left: -45px; top:30px; }
  .abo_box .zeitung img { width: 330px; }
  .footer_adresse { text-align: center; }
  .footer_adresse .col-md-4 { margin-top: 10px; }

  /*** Suchmaske ***/
  .suchmaske .form-control { width: 100%; }
  .agenda_liste .suchmaske .form-control { width:100%; margin-right:0;}
  .agenda_liste .suchmaske .form-control[name=daterange] { width:100%; }

  /*** Formular ***/
  .form-horizontal input, .form-horizontal textarea { width: 100%; }


  /*** Nach oben ***/
  .nach_oben span { display: block !important}
  .nach_oben i {text-align: center;margin-left: 5px; float: right;}


  /*** In Bearbeitung ***/
  .inbearbeitung { background: url("../img/footer_foto.jpg") no-repeat bottom center;-webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover; background-size: cover;height: 100%; position: absolute; top: 0; width: 100%; padding-top: 30px;background-position: 0px 290px; text-align: center;}
  .inbearbeitung .text { font-size: 16px; line-height: 23px;}
  .inbearbeitung img { margin-bottom: 20px;}


}